import React from "react"
import Layout from "../components/layout";
import WorksContent from "../components/works";

import { Card, CardHeader, CardMedia, Grid, Box, CardActionArea } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const Works = () => (
  <Layout id="notfound" title="Works">
    <WorksContent />
  </Layout>
);
export default Works;