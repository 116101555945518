import React from "react"

import { Card, CardHeader, CardMedia, Grid, Box, CardActionArea } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const works = [
  {
    title: "Pixel",
  },
  {
    title: "Mountain",
  },
  {
    title: "Stardust",
  },
  {
    title: "MtFuji",
  },
];

const useStyles = makeStyles((theme) => ({
  media: {
    width: "100%",
    height: "0",
    paddingTop: "100%",
  },
  cardHeader: {
    background: "transparent",
    color: "white",
  }
}));

const renderCards = () => {
  const classes = useStyles();
  return works.map(work => {
    return (
      <Grid item xs={12} sm={6} key={work.title}>
        <Card variant="outlined" elevation={3} className={classes.card}>
          <CardActionArea style={{ textDecoration: "none", color: "inherit" }} href={`https://www.instagram.com/explore/tags/takeru_okado_${work.title.toLowerCase()}/`} target="_blank" rel="noopener noreferrer">
            <CardHeader title={work.title} className={classes.CardHeader} />
            <CardMedia image={`/images/work_${work.title}.jpg`} title={work.title} className={classes.media} />
          </CardActionArea>
        </Card>
      </Grid>

    );
  });
}

const Works = () => {
  return (
    <Box padding={2}>
      <Grid container spacing={10}>
        {renderCards()}
      </Grid>
    </Box>
  );
};
export default Works;